@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Outfit', sans-serif;
}

.white path {
  fill: white;
}

button {
  font-family: 'Outfit';
}

::-webkit-scrollbar {
  display: none;
  width: 0;
}

div {
  scrollbar-width: 0;
  -ms-overflow-style: none;
}

@media screen and (max-width: 900px) {

  html,
  body {
    font-size: 14.5px;
  }
}

@media screen and (max-width: 400px) {

  html,
  body {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {

  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}


.MuiPaper-root{
  background: #fff !important;
}

